<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 측정년도 -->
          <c-datepicker
            name="measYear"
            type="year"
            :range="true"
            label="측정년도"
            defaultStart="-3y"
            defaultEnd="0y"
            v-model="searchParam.period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="WORK_MEAS_QUARTER_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="measQuarter"
            label="분기"
            v-model="searchParam.measQuarter"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            trueLabel="소음"
            falseLabel="소음제외"
            label="소음/소음제외"
            name="noiseFlag"
            v-model="searchParam.noiseFlag"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-harmful-factor
            :editable="editable"
            label="유해인자"
            name="hazardCd"
            v-model="searchParam.hazardCd">
          </c-harmful-factor>
        </div>
      </template>
    </c-search-box>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        title="유해인자별 결과 목록"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :columnSetting="false"
        :merge="grid.merge"
        @linkClick="linkClick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'work-measure-result-factors',
  data() {
    return {
      grid: {
        merge: [
          // { index: 0, colName: 'hazardCd' },
          // { index: 1, colName: 'hazardCd' },
        ],
        columns: [
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        measQuarter: null,
        measYear: '',
        startYmd: '',
        endYmd: '',
        speHealthFlag: null,
        exposureFlag: null,
        hazardClassFstCd: null,
        noiseFlag: 'Y',
        hazardCd: '',
        hazardName: '',
        year: [],
      },
      workFlagItems: [
        { code: 'Y', codeName: '관련' },
        { code: 'N', codeName: '미관련' },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      count: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sai.workmeas.result.factor.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.setHeader();
      },);
    },
    setHeader() {
      if (this.searchParam.noiseFlag === 'N') {
        this.grid.merge =  [
          { index: 0, colName: 'hazardCd' },
          { index: 1, colName: 'hazardCd' },
        ],
        this.grid.columns = [
          {
            fix: true,
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자',
            align: 'center',
            style: 'width:180px',
            sortable: false,
          },
          {
            fix: true,
            name: 'casNo',
            field: 'casNo',
            label: 'CAS NO.',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'measYear',
            field: 'measYear',
            label: '측정년도',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'measQuarterName',
            field: 'measQuarterName',
            label: '분기',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'processCd',
            field: 'processCd',
            label: '공정명',
            align: 'center',
            style: 'width:230px',
            sortable: false,
          },
          {
            name: 'workPlace',
            field: 'workPlace',
            label: '단위작업장소',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'measDate',
            field: 'measDate',
            label: '측정일자',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workCount',
            field: 'workCount',
            label: '근로자수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'shiftType',
            field: 'shiftType',
            label: '근로형태',
            style: 'width:140px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'occurTime',
            field: 'occurTime',
            label: '발생시간',
            align: 'right',
            sortable: true,
          },
          {
            name: 'measPositionName',
            field: 'measPositionName',
            label: '측정위치',
            align: 'left',
            sortable: true,
          },
          {
            name: 'workerName',
            field: 'workerName',
            label: '근로자명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measTime',
            field: 'measTime',
            label: '시작~종료시간',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measCount',
            field: 'measCount',
            label: '측정횟수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measValueEtc',
            field: 'measValueEtc',
            label: '측정치기타',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measValue',
            field: 'measValue',
            label: '측정치',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'twaPrevEtc',
            field: 'twaPrevEtc',
            label: '전회기타',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaPrev',
            field: 'twaPrev',
            label: '전회',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaCurrEtc',
            field: 'twaCurrEtc',
            label: '금회기타',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaCurr',
            field: 'twaCurr',
            label: '금회',
            align: 'center',
            sortable: true,
          },
          {
            name: 'afterExposureStandard',
            field: 'afterExposureStandard',
            label: '보정후<br>노출기준',
            align: 'center',
            sortable: true,
          },
          {
            name: 'exposureDivision',
            field: 'exposureDivision',
            label: '노출구분',
            align: 'center',
            sortable: true,
          },
          {
            name: 'exposureUnit',
            field: 'exposureUnit',
            label: '노출단위',
            align: 'center',
            sortable: true,
          },
          {
            name: 'exposureResult',
            field: 'exposureResult',
            label: '결과',
            align: 'center',
            sortable: true,
          },
        ]
      } else {
        this.grid.merge =  [
        ],
        this.grid.columns = [
          {
            name: 'measYear',
            field: 'measYear',
            label: '측정년도',
            align: 'center',
            style: 'width:100px',
            fix: true,
            sortable: false,
          },
          {
            name: 'measQuarterName',
            field: 'measQuarterName',
            label: '분기',
            align: 'center',
            style: 'width:100px',
            fix: true,
            sortable: false,
          },
          {
            fix: true,
            name: 'processCd',
            field: 'processCd',
            label: '공정명',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            fix: true,
            name: 'workPlace',
            field: 'workPlace',
            label: '단위작업장소',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'workContents',
            field: 'workContents',
            label: '작업내용',
            style: 'width:250px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'workCount',
            field: 'workCount',
            label: '근로자수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'shiftType',
            field: 'shiftType',
            label: '근로형태',
            style: 'width:140px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'occurType',
            field: 'occurType',
            label: '발생형태',
            align: 'center',
            sortable: true,
          },
          {
            name: 'occurTime',
            field: 'occurTime',
            label: '발생시간',
            align: 'right',
            sortable: true,
          },
          {
            name: 'measPositionName',
            field: 'measPositionName',
            label: '측정위치',
            align: 'left',
            sortable: true,
          },
          {
            name: 'workerName',
            field: 'workerName',
            label: '근로자명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measTime',
            field: 'measTime',
            label: '시작~종료시간',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measCount',
            field: 'measCount',
            label: '측정횟수',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measValueEtc',
            field: 'measValueEtc',
            label: '측정치기타',
            align: 'center',
            sortable: true,
          },
          {
            name: 'measValue',
            field: 'measValue',
            label: '측정치',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'twaPrevEtc',
            field: 'twaPrevEtc',
            label: '전회기타',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaPrev',
            field: 'twaPrev',
            label: '전회',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaCurrEtc',
            field: 'twaCurrEtc',
            label: '금회기타',
            align: 'center',
            sortable: true,
          },
          {
            name: 'twaCurr',
            field: 'twaCurr',
            label: '금회',
            align: 'center',
            sortable: true,
          },
          {
            name: 'exposureStandard',
            field: 'exposureStandard',
            label: '노출기준',
            align: 'center',
            sortable: true,
          },
          {
            name: 'exposureResult',
            field: 'exposureResult',
            label: '결과',
            align: 'center',
            sortable: true,
          },
        ]
      }
    },
    linkClick(result, col) {
      if (col && col.name === 'hazardName') {
        this.popupOptions.target = () => import(`${"./harmfulFactorsDetail.vue"}`);
        this.popupOptions.title = '유해인자 상세';
        this.popupOptions.param = {
          hazardCd: result ? result.hazardCd : '',
        };
        this.popupOptions.width = '80%';
      } else if (col && col.name === 'measDate') {
        this.popupOptions.target = () => import(`${"./workMeasureResultDetail.vue"}`);
        this.popupOptions.title = '작업환경측정결과 상세';
        this.popupOptions.param = {
          workMeasurementPlanId: result ? result.workMeasurementPlanId : '',
        };
        this.popupOptions.width = '95%';
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
